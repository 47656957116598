const { registerLinkResolver } = require('@kilokilo/gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver');

registerLinkResolver(linkResolver);

exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    const currentPosition = getSavedScrollPosition(location)

    window.scrollTo(0, 0)

    return false
}

exports.onServiceWorkerUpdateReady = () => {
    window.location.reload(true)
}